const CheckBoxIcon = () => {
    // return (
    //     <svg width="16" height="16" viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg">
    //         <rect x="0.5" y="0.5" width="15" height="15" rx="2" />
    //     </svg>
    // );
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="23" height="23" rx="7.5" fill="white" stroke="#C6C6C6" />
        </svg>
    );
};

export default CheckBoxIcon;
