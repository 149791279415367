import { ReactNode, SetStateAction, Dispatch, createContext, useState } from "react";
import SmartProfile from "~/services/SmartProfile";
import { sp_hid, sp_credentials } from "~/services/SmartProfile/defines";

interface AnalyticsSettings {
    service: any;
    service_ids: any;
    credentials: any;
    user_consent: boolean;
    set_consent: Dispatch<SetStateAction<boolean>>;
}

export const AnalyticsContext = createContext<AnalyticsSettings>({
    service: null,
    service_ids: {},
    credentials: {},
    user_consent: false,
    set_consent: () => {},
});

const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
    const [consent, setConsent] = useState(document.cookie.includes("consent_track=true"));

    const smartProfileSettings = {
        service: SmartProfile,
        service_ids: sp_hid,
        credentials: sp_credentials,
        user_consent: consent,
        set_consent: setConsent,
    };

    return <AnalyticsContext.Provider value={smartProfileSettings}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;
