const CheckBoxCheckedIcon = () => {
    // return (
    //     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <rect width="16" height="16" rx="2" fill="white" />
    //         <path
    //             d="M12.4149 4L6.01716 10.3978L3.58507 7.9657L2.78397 8.76681L6.01716 12L13.2161 4.80111L12.4149 4Z"
    //             fill="#F56454"
    //         />
    //     </svg>
    // );

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="8" fill="#4B4B4B" />
            <path
                d="M6 11.8974L10 16L18 8"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CheckBoxCheckedIcon;
