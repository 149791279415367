import { useContext } from "react";
import { AnalyticsContext } from "~/providers/AnalyticsProvider";

const useAnalytics = () => {
    const {
        service: sp_trackerv3,
        user_consent: sp_tracking_consent,
        service_ids: sp_hid,
        credentials: smartProfileLoginParams,
    } = useContext(AnalyticsContext);

    const siteName = import.meta.env.VITE_SITE_NAME;
    const consent = import.meta.env.VITE_SP_ENABLED === "true" && sp_tracking_consent;

    function sp_track_contact(_sp_params: any) {
        // smartProfileLoginParams init in base.html
        // use php sessionId in smartProfileLogin so we could send the login request for every new user session
        if (!consent) return;

        if (
            sp_tracking_consent &&
            smartProfileLoginParams?.login &&
            localStorage.getItem("smartProfileLogin") !== smartProfileLoginParams?.sessionId
        ) {
            _sp_params["login"] = smartProfileLoginParams.login;
            _sp_params["EMAIL1"] = smartProfileLoginParams.login;
            _sp_params["PROFIL1"] = smartProfileLoginParams.profil;
            sp_track_params(_sp_params, "contact");
            localStorage.setItem("smartProfileLogin", smartProfileLoginParams.sessionId);
            delete _sp_params["login"];
        }
        if (!smartProfileLoginParams?.login && localStorage.getItem("smartProfileLogin")) {
            localStorage.removeItem("smartProfileLogin");
        }
    }

    function sp_set_hids(_hids: any) {
        if (typeof _hids == "object") {
            for (const key of Object.keys(_hids)) {
                sp_hid[key] = _hids[key];
            }
        }
    }

    /*
    function sp_track_acpm() {
        const _consent = sp_tracking_consent,
            _ci = "";

        if (!sp_tracking_consent) {
            return;
        }

        let spParams = ["?_hid=" + sp_hid["acpm"]];

        if (isMobileOrTablet()) {
            spParams.push("&_site=Site Mobile");
        } else {
            spParams.push("&_site=Site Desktop");
        }

        spParams.push("&_title=" + getTitle());

        sp_trackerv3.doTrack(spParams, _ci, _consent, _spURL_acpm);
    }
    */

    function sp_track_params(_sp_params: any, _sp_hid_name: string) {
        if (!consent) return;

        const _consent = sp_tracking_consent,
            _ci = "";

        let spParams = [];

        if (typeof _sp_hid_name !== "undefined" && _sp_hid_name != "") {
            spParams.push("?_hid=" + sp_hid[_sp_hid_name]);
        } else {
            spParams.push("?_hid=" + sp_hid["stats"]);
        }
        spParams.push("&_site=" + siteName);

        if (typeof _sp_params !== "undefined") {
            if (typeof _sp_params.length !== "undefined") {
                for (const param of _sp_params) {
                    spParams.push("&" + param);
                }
            } else if (typeof _sp_params == "object") {
                for (const key of Object.keys(_sp_params)) {
                    spParams.push("&" + key + "=" + encodeURIComponent(_sp_params[key]));
                }
            }
        }
        sp_trackerv3.doTrack(spParams, _ci, _consent);
    }

    function sp_click(_ctyp: string, _clab: string, _cfrom: string, _cto: string, _sp_params: any) {
        if (!consent) return;
        const _consent = sp_tracking_consent,
            _ci = "";

        let spParams = ["?_hid=" + sp_hid["clics"]];

        spParams.push("&_site=" + siteName);
        spParams.push("&_ctyp=" + encodeURIComponent(_ctyp));
        spParams.push("&_clab=" + encodeURIComponent(_clab));
        spParams.push("&_cfrom=" + encodeURIComponent(_cfrom));
        spParams.push("&_cto=" + encodeURIComponent(_cto));

        if (_sp_params && typeof _sp_params !== "undefined") {
            if (typeof _sp_params.length !== "undefined") {
                for (const param of _sp_params) {
                    spParams.push("&" + param);
                }
            } else if (typeof _sp_params == "object") {
                for (const key of Object.keys(_sp_params)) {
                    spParams.push("&" + key + "=" + encodeURIComponent(_sp_params[key]));
                }
            }
        }

        sp_trackerv3.doTrack(spParams, _ci, _consent);
    }

    return {
        sp_track_contact,
        sp_set_hids,
        // sp_track_acpm,
        sp_track_params,
        sp_click,
    };
};

export default useAnalytics;
