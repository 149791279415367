import * as sp_settings from "./defines";

/* tracker js pour smartProfile */
const SmartProfile = (function () {
    const _spURL = sp_settings._spURL;

    /* private stuff */
    const generateID = function () {
        return (
            "" +
            new Date().getTime().toString(16) +
            Math.floor(1e7 * Math.random()).toString(16) +
            Math.floor(1e7 * Math.random()).toString(16)
        );
    };

    const getDomain = function () {
        return encodeURIComponent(window.location.protocol + "//" + window.location.hostname);
    };

    const getTimezoneOffset = function () {
        const _ctz = new Date().getTimezoneOffset();
        return Math.round((_ctz == new Date(20010101).getTimezoneOffset() ? _ctz : _ctz + 60) / -60);
    };

    const getViewportSize = function () {
        let _w, _h;
        if (window.innerWidth !== undefined && window.innerHeight !== undefined) {
            _w = window.innerWidth;
            _h = window.innerHeight;
        } else {
            _w = document.documentElement.clientWidth;
            _h = document.documentElement.clientHeight;
        }

        if (_w !== undefined) {
            return _w + "x" + _h;
        }

        return "n/a";
    };

    const getUrlParamValue = function (param: any) {
        const qs = window.location.search.substring(1);
        const vars = qs.split("&");
        for (const element of vars) {
            const pair = element.split("=");
            if (pair[0].toLowerCase() == param.toLowerCase()) {
                const _val = pair[1].split("#");
                return _val[0];
            }
        }
        return "";
    };
    const setCookie = function (_name: string, _value: string, _expireInDays: number) {
        const _expireDate = new Date();
        _expireDate.setTime(_expireDate.getTime() + _expireInDays * 24 * 60 * 60 * 1000);
        document.cookie =
            _name +
            "=" +
            (encodeURIComponent(_value) || "") +
            (_expireInDays ? ";expires=" + _expireDate.toUTCString() : "") +
            "; path=/; SameSite=None";
    };
    const getCookie = function (_name: string) {
        const nameEQ = _name + "=";
        const ca = document.cookie.split(";");
        for (const element of ca) {
            let c = element;

            while (c.charAt(0) == " ") {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }

        return null;
    };

    const getSPCookie = function () {
        const cVal = getCookie("sp_i");
        const _ckValue = cVal ? cVal : generateID();
        setCookie("sp_i", _ckValue, 395);
        return _ckValue;
    };

    const _params = {
        _consent: 0,
        _hid: "",
        _sp_url: _spURL,
        _cr: new Date().getTime(),
        _ck: navigator.cookieEnabled ? 1 : 0,
        _ua: navigator.userAgent,
        _fv: -1,
        _dom: getDomain(),
        _title: encodeURIComponent(document.title),
        _res: window.screen ? screen.width + "x" + screen.height : "n/a",
        _col: window.screen ? screen.colorDepth : "n/a",
        _vp: getViewportSize(),
        _tz: getTimezoneOffset(),
        _h: "",
        _fp: getSPCookie(),
        _event: "",
        _sp_con: "",
        _utm_campaign: "",
        _utm_source: "",
        _utm_medium: "",
        _utm_content: "",
        _ready: false,
    };
    const getH = function (_hid: string) {
        const _qs =
            "?action=getH&_hid=" + _hid + "&_res=" + _params._res + "&_col=" + _params._col + "&_tz=" + _params._tz;
        fetch(_params._sp_url + _qs)
            .then(function (response) {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(function (_json) {
                _params._h = _json.h;
                _params._ready = true;
            });
    };

    /* public part */
    return {
        isReady: function () {
            return _params._ready;
        },
        getHash: function (_hid: string) {
            getH(_hid);
        },
        doTrackClick: function (
            ctyp: string,
            clab: string,
            cfrom: string,
            cto: string,
            hid: string,
            cst: any,
            site: string
        ) {
            let sp = new Array();
            sp[0] = "?_hid=" + hid;
            sp[1] = "&_ctyp=" + ctyp;
            sp[2] = "&_clab=" + clab;
            sp[3] = "&_cfrom=" + cfrom;
            sp[4] = "&_cto=" + cto;

            if (typeof site !== "undefined" && site != "") {
                sp[5] = "&_site=" + site;
            }
            this.doTrack(sp, "", cst, "");
        },
        doTrack: function (sp: any, ci: string, cst: any, spURL: string) {
            const _spURL = typeof spURL !== "undefined" && spURL != "" ? spURL : _params._sp_url;

            let smartParam = "";
            _params._consent = typeof cst !== "undefined" ? cst : 0;

            for (const element of sp) {
                if (!_params._consent) {
                    const _crit = element.trim().substr(1, 5);
                    if (_crit != "login") {
                        smartParam += element;
                    } else {
                        localStorage.removeItem("smartProfileLogin");
                    }
                } else {
                    smartParam += element;
                }
            }

            if (ci != "") {
                smartParam += "&_ci=" + ci;
            }

            smartParam +=
                "&_cr=" +
                Math.round(Math.random() * 1000000000000000) +
                "&_fv=" +
                encodeURIComponent(_params._fv) +
                "&_dom=" +
                encodeURIComponent(window.location.protocol + "//" + window.location.hostname) +
                "&_ref=" +
                encodeURIComponent(document.referrer.substring(0, 254)) +
                "&_res=" +
                encodeURIComponent(_params._res) +
                "&_col=" +
                encodeURIComponent(_params._col) +
                "&_url=" +
                encodeURIComponent(window.location.href) +
                "&_vp=" +
                _params._vp +
                "&_tz=" +
                _params._tz +
                "&_ck=" +
                _params._ck +
                "&_fp=" +
                _params._fp +
                "&_optin=" +
                _params._consent +
                "&_event=" +
                encodeURIComponent(getUrlParamValue("event")) +
                "&_sp_con=" +
                encodeURIComponent(getUrlParamValue("sp_con")) +
                "&utm_campaign=" +
                encodeURIComponent(getUrlParamValue("utm_campaign")) +
                "&utm_source=" +
                encodeURIComponent(getUrlParamValue("utm_source")) +
                "&utm_medium=" +
                encodeURIComponent(getUrlParamValue("utm_medium")) +
                "&utm_content=" +
                encodeURIComponent(getUrlParamValue("utm_content"));

            const smartImageF = new Image();
            smartImageF.src = _spURL + smartParam;
        },
    };
})();

export default SmartProfile;
