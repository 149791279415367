import React from "react";
import { useToggleState } from "react-stately";
import { useCheckbox, AriaCheckboxProps } from "react-aria";
import CheckBoxIcon from "../Icon/CheckBox";
import CheckBoxCheckedIcon from "../Icon/CheckBoxChecked";

interface CheckboxProps extends AriaCheckboxProps {
    children: React.ReactNode;
    className?: string;
}

function Checkbox(props: CheckboxProps) {
    const { children, className } = props;
    const state = useToggleState(props);
    const ref = React.useRef<HTMLInputElement>(null);
    const { inputProps } = useCheckbox(props, state, ref);

    return (
        <label className={`checkbox cursor-pointer relative items-center text-label text-foreground-dark ${className}`}>
            <input {...inputProps} ref={ref} />
            <span className="z-0">{state.isSelected ? <CheckBoxCheckedIcon /> : <CheckBoxIcon />}</span>
            {children}
        </label>
    );
}

export default Checkbox;
